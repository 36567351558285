import React from "react";

interface Props {
    id: number | string;
    title?: string;
    activeTab: number | string;
    setActiveTab: any;
    className: string;
}

const TabNavItem = ({ id, title, activeTab, setActiveTab, className }:Props) => {

    const handleClick = () => {
        setActiveTab(id);
    };

    return (
        <h2 onClick={handleClick} className={`nav-item mb-0 ${activeTab === id ? "active" : ""}`}>
            <div className={className}>{title}</div>
        </h2>
    );
};
export default TabNavItem;