import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import ReactMarkdown from 'react-markdown';

import { Header2, Header5 } from 'src/components/core/typography';
import { Section, SectionFullWidth } from 'src/components/core/Section';
import { CoursePageInfoSectionStoryblok } from 'src/storyblok-component-types';
import { getFilename, findMatchingLocalFileNode, DynamicImage, Slug } from 'src/lib';
import { AccordionItem } from 'src/components/shared/Accordion';

import RichText from '../core/RichText';

interface StoryProps {
    story: Slug;
}

export default (props: CoursePageInfoSectionStoryblok & StoryProps) => {
    const infoCards = props.info || [];
    const random = Math.floor(Math.random() * 100000) + 1;
    const heroImageLocalFileNode = findMatchingLocalFileNode(getFilename(props.image?.filename || ""), props.story);
    const [clicked, setClicked] = useState<number>(0);
    const [showAll, setShowAll] = useState(false);

    const handleToggle = (index: number) => {
        setClicked(clicked === index ? -1 : index); // Toggles the clicked item
    };

    const imageData = useStaticQuery(imageQuery);
    return <SectionFullWidth className={props.className || ""}>
        <Section className="grid grid-cols-12 md:gap-10">
            <div className={props.layout == "title-left" || heroImageLocalFileNode ? "col-span-12 md:col-span-4 lg:mr-16" : "hidden"}>
                {props.layout == "title-left" ? <><Header2>{props.title}</Header2><ReactMarkdown>{props.desc}</ReactMarkdown></> : ''}
                {!heroImageLocalFileNode ? '' : <DynamicImage fileNode={heroImageLocalFileNode} alt={props.title} className="mb-6 md:mb-0" />}
            </div>
            <div className={props.layout == "title-left" || heroImageLocalFileNode ? "col-span-12 md:col-span-8 col-end-13" : "col-span-12 col-end-13"}>
                <div className="p">
                    {props.layout == "title-right" ? <div className="mb-6 text-center"><Header2>{props.title}</Header2><RichText document={props.desc} className="text-lynch text-center w-5/6 m-auto text-md lg:text-lg mb-8" /></div> : ""}
                </div>
                <div className="hidden lg:flex justify-evenly">
                    <Header5 className="mb-0 !text-lynch w-6/12 pl-6">Topic</Header5>
                    <Header5 className="mb-0 !text-lynch w-5/12">Percentage of Exam</Header5>
                    <Header5 className="mb-0 w-1/12">&nbsp;</Header5>
                </div>

                {/* New Accordion */}
                <div className="accordion w-full" id={`accordion_${random}`}>
                    {infoCards.slice(0, showAll ? infoCards.length : 3).map((card, index) => (
                        <AccordionItem
                            key={index}
                            i={index}
                            title={card.Title}
                            description={card.Description}
                            percentage={card.Percentage}
                            onToggle={() => handleToggle(index)}
                            active={clicked === index}
                            faq={false}
                            className={`border-b border-mischka p-6 overflow-hidden w-full ${props.layout == "title-right" ? "bg-transparent" : "bg-transparent"}`}
                        />
                    ))}
                </div>
                <div className="flex justify-center mt-8">
                    <button
                        className="text-navy font-semibold flex gap-2 items-center"
                        onClick={() => setShowAll(!showAll)}
                    >
                        {showAll ? 'Hide Topics' : 'Show All Topics'}
                        {showAll ? 
                            <DynamicImage fileNode={imageData.chevron.nodes[0]} alt="Chevron Icon" width={10} height={10} className="rotate-180" loading="lazy"/> : 
                            <DynamicImage fileNode={imageData.chevron.nodes[0]} alt="Chevron Icon" width={10} height={10} className="" loading="lazy"/>
                        }
                    </button>
                    
                </div>
                <div className="mt-6">
                    {props.disclaimer == '' ? '' : <RichText className="text-center text-lynch" document={props.disclaimer} />}
                </div>
            </div>
        </Section>
    </SectionFullWidth>;
}

const imageQuery = graphql`
query {
    chevron:allFile(filter: { name: { eq: "usx_chev_down" }}) {
        nodes {
            name
            publicURL
            childImageSharp {
                gatsbyImageData
            }
        }
    }
}
`;
