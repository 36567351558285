import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import ReactMarkdown from 'react-markdown';

import { Text, Header1, Header2, Header5 } from 'src/components/core/typography';
import { Section } from 'src/components/core/Section';
import { CourseData, CourseType, CourseTypeData } from 'src/components/coursePages/types';
import { courseLang } from 'src/lib/courseLang';
import { getFilename, findMatchingLocalFileNode, DynamicImage, Slug, LocalFileSource } from 'src/lib';
import { IconCardStoryblok, CoursePageStoryblok } from "src/storyblok-component-types";
import PurchaseButton from './lib/purchaseFlow';
import { Tooltip } from './tooltip';
import RichText from '../core/RichText';
import { BuyNowButton } from './buttonV2'; 

const Tag = (props: { children: string | JSX.Element | JSX.Element[], className?: string }) => {
    const imageData = useStaticQuery(imageQuery);
    return <div className={`text-sm text-center md:text-left w-full mt-2 text-dark-blue ${props.className || ""}`}>
        <DynamicImage fileNode={imageData.medal.nodes[0]} alt="Medal Icon" width={15} height={15} className="mr-1 -mt-1 inline" loading="lazy"/>
        {props.children}
    </div>;
}

const Alert = (props: { children: string | JSX.Element | JSX.Element[], className?: string }) => {
    const imageData = useStaticQuery(imageQuery);
    return <div className="bg-cinderella text-milano rounded-xl p-2 mb-2 flex flex-row items-center justify-center text-[12.8px] text-center">
        <DynamicImage fileNode={imageData.alert.nodes[0]} alt="Alert Icon" width={12} height={12} className="hidden lg:block mr-2" loading="lazy"/>
        {props.children}
    </div>;
};

const MoneyBackGuarantee = ({x}:{x:CourseType}) => {
    const lang = courseLang(x);
    const imageData = useStaticQuery(imageQuery);
    return <div className="bg-hint-green text-green-700 rounded-xl p-2 mb-8 flex flex-row items-center justify-center text-[12.8px] text-center">
        <DynamicImage fileNode={imageData.dollar.nodes[0]} alt="Dollar Icon" width={15} height={15} className="hidden lg:block mr-2" loading="lazy"/>
        {lang == "lang-es" ? "Garantía de devolución de dinero" : "100% Money-Back Guarantee"}
        <Tooltip message={lang == "lang-es" ? "¡Estamos seguros de que te encantarán nuestros cursos! De lo contrario, proporcionamos reembolsos completos sujetos a nuestra política de reembolso." : "We're confident you'll love our programs! If not, we provide full refunds subject to our refund policy."}>
        <DynamicImage fileNode={imageData.info.nodes[0]} alt="Info Icon" width={14} height={14} className="ml-2" loading="lazy"/>
        </Tooltip>
    </div>;
};

const EnrollButton = ({ children, courseType, id }: { children: React.ReactNode, courseType: CourseTypeData, id:string }) => {
    // basset needs a modal
    return <PurchaseButton courseType={courseType}>
        <div id={id} className="btn btn-primary w-full">
            { children }
        </div>
    </PurchaseButton>
};

const BusinessButton = ({x}:{x:CourseType}) => {
    const lang = courseLang(x);
    return <div>
        <div className="w-full text-center">
            <h3 className="text-bluewood leading-5 mb-2">{lang == "lang-es" ? "¿Está inscribiendo a su personal?" : "Are You Enrolling Your Staff?"}</h3>
            <a className="text-navy font-medium" href="https://hello.userve.com/schedule" target="_blank" rel="noopener">{lang == "lang-es" ? "Hablar con un Asesor" : "Speak with an Advisor"}</a>
        </div>
    </div>
};

const Features = ({ features, heroStory }: { features: IconCardStoryblok[], heroStory: Slug }) => {
    return <div className="flex flex-col gap-4 text-left mt-6">
        {features.map((feature, i) => {
            const gatsbyImageFileNode = findMatchingLocalFileNode(getFilename(feature.Icon?.filename || ""), heroStory);
            return <div className="flex flex-row gap-3 align-top" key={`features-${i}`}>
                <DynamicImage fileNode={gatsbyImageFileNode} width={20} height={20} alt={feature.Title || ""} className="block max-w-none w-4 mr-2" />
                <ReactMarkdown className="text-sm">{feature.Title || ""}</ReactMarkdown>
            </div>
        })}
    </div>
}

const Benefits = ({ benefits, heroStory }: { benefits: IconCardStoryblok[], heroStory: Slug }) => {
    return <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {benefits.map((benefit, i) => {
            const gatsbyImageFileNode = findMatchingLocalFileNode(getFilename(benefit.Icon?.filename || ""), heroStory);
            return <div className="flex flex-col gap-4 items-center md:items-start" key={`benefits-${i}`}>
                <DynamicImage fileNode={gatsbyImageFileNode} width={60} height={60} className="block max-w-none h-16" alt={benefit.Title || ""} />
                <Header5 className="!mb-0">{benefit.Title || ""}</Header5>
                <RichText className="!mb-0 text-lynch" document={benefit.Description || ""}/>
            </div>
        })}
    </div>
}

const ProgramOptions = () => {
    return <div className="mt-6">
        <a href="#programOptions">
            <div className="text-center text-sm text-navy font-semibold">See More Program Options</div>
        </a>
    </div>
}

export default ({ content, heroStory, context }: { content: CoursePageStoryblok, context: CourseData, heroStory: Slug }) => {
    const imageData = useStaticQuery(imageQuery);
    let defaultGraphicFileNode: LocalFileSource | undefined;
    switch (true) {
        case (context.type === "fh"):
            defaultGraphicFileNode = imageData.foodHandler.nodes[0];
            break;
        case (context.type === "fh_ansi"):
            defaultGraphicFileNode = imageData.fh_ansi.nodes[0];
            break;
        case (context.type === "fh_anab"):
            defaultGraphicFileNode = imageData.foodHandler.nodes[0];
            break;
        case (context.type === "fh_allergen"):
            defaultGraphicFileNode = imageData.fh_allergen.nodes[0];
            break;
        case (context.type === "fpm"):
            defaultGraphicFileNode = imageData.fpm.nodes[0];
            break;
        case (context.type === "fpm_alt"):
            defaultGraphicFileNode = imageData.fpm_alt.nodes[0];
            break;
        case (context.type === "al"):
            defaultGraphicFileNode = imageData.alcoholServer.nodes[0];
            break;
        case (context.type === "al_es"):
            defaultGraphicFileNode = imageData.al_es.nodes[0];
            break;
        case (context.type === "al_ri"):
            defaultGraphicFileNode = imageData.alcoholServer.nodes[0];
            break;
        case (context.type === "al_mi"):
            defaultGraphicFileNode = imageData.alcoholServer.nodes[0];
            break;
        case (context.type === "fm"):
            defaultGraphicFileNode = undefined;
            break;
        case (context.type === "rbs"):
            defaultGraphicFileNode = imageData.rbs.nodes[0];
            break;
        case (context.type === "rbses"):
            defaultGraphicFileNode = imageData.rbses.nodes[0];
            break;
        case (context.type === "basset"):
            defaultGraphicFileNode = imageData.basset.nodes[0];
    }

    const lang = courseLang(context.type);

    const gatsbyImageFileNode = findMatchingLocalFileNode(getFilename(content.Image?.filename || ""), heroStory);
    const imageComp = <DynamicImage fileNode={gatsbyImageFileNode || defaultGraphicFileNode} alt={content.title || "Hero image"} className="rounded-xl h-56 md:h-96" imgStyle={{borderRadius:'1rem'}} />;
    // replace state placeholder with state name
    if (!content.price) throw Error(`Price was not found for page ${context.url}`);

    let productNumber;
    if (context.type == "fpm") {
        productNumber = 7;
    } else if (context.type == "fpm_alt") {
        productNumber = 26;
    }

    return <Section id="course-page-product-hero" className="flex flex-col md:grid grid-cols-1 md:grid-cols-3 grid-flow-col md:grid-flow-row gap-8 lg:gap-0">
         <div className="order-last md:order-first col-start-1 col-span-1 md:row-start-1 row-span-3 flex flex-col h-full lg:mr-16 text-center md:text-left gap-8">
            <div className="bg-hawkes-blue p-8 rounded-md">
                <div className="mb-1 text-2xl font-extrabold text-center">{content.price}</div>
                { content.price_original ? <div className="text-sm text-center text-lynch">List Price: <span className="line-through">{content.price_original}</span></div> : null }
                
                <ReactMarkdown {...({ breaks: true } as any)} className="mt-4 mb-6 text-sm text-lynch text-center">{content.price_desc || ""}</ReactMarkdown>

                { (content.type == "fpm") ? <BuyNowButton id="course-page-enroll-button" productNumber={productNumber}><p className="text-inherit !mb-0" data-test="enroll-button">{lang == "lang-es" ? "Regístrate" : "Enroll Now"}</p></BuyNowButton> :  <EnrollButton id="course-page-enroll-button" courseType={{ type: context.type, enroll: context.enroll || "" }}><p className="text-inherit !mb-0" data-test="enroll-button">{lang == "lang-es" ? "Regístrate" : "Enroll Now"}</p></EnrollButton>
                }
                { context.type == "fpm" ? <ProgramOptions /> : null }
                <Features features={content.features || []} heroStory={heroStory}/>
            </div>

            { content.image ? <DynamicImage fileNode={{ publicURL: content.image?.filename }} alt={content.image.alt || "Userve"} className="m-auto w-[270px] md:w-full" loading="lazy" /> : null }

            <BusinessButton x={context.type}/>
            { content.alert ? <Alert>{content.alert}</Alert> : null}
        </div>
        <div className="order-first md:order-last col-start-2 col-span-2 md:row-start-1 row-span-2">
            <div className="md:hidden mb-8">{imageComp}</div>
            <Header1 className="text-center md:text-left leading-8 !text-2xl md:!text-3xl lg:!text-4xl mb-0">{content.title || ""}</Header1>
            {content.tag ? <Tag>{content.tag}</Tag> : null}
            <Text className="text-center md:text-left mt-4 mb-0 md:mb-12 text-md lg:text-lg">{content.desc || ""}</Text>
            <span className="hidden md:block">{imageComp}</span>
        </div>
       
    </Section>;
};

// optimized images
const imageQuery = graphql`
query {
    foodHandler:allFile(filter: { name: { eq: "usx_fh_ge_hero" } }) {
        nodes {
            name
            extension
            childImageSharp {
                gatsbyImageData
            }
            publicURL
        }
    }
    fh_ansi:allFile(filter: { name: { eq: "usx_fh_ge_hero" } }) {
        nodes {
            name
            extension
            childImageSharp {
                gatsbyImageData
            }
            publicURL
        }
    }
    fh_anab:allFile(filter: { name: { eq: "usx_fh_ge_hero" } }) {
        nodes {
            name
            extension
            childImageSharp {
                gatsbyImageData
            }
            publicURL
        }
    }
    fh_allergen:allFile(filter: { name: { eq: "usx_fh_al_hero" } }) {
        nodes {
            name
            extension
            childImageSharp {
                gatsbyImageData
            }
            publicURL
        }
    }
    fpm:allFile(filter: { name: { eq: "usx_fm_ge_hero" } }) {
        nodes {
            name
            extension
            childImageSharp {
                gatsbyImageData
            }
            publicURL
        }
    }
    fpm_alt:allFile(filter: { name: { eq: "usx_fm_ge_hero" } }) {
        nodes {
            name
            extension
            childImageSharp {
                gatsbyImageData
            }
            publicURL
        }
    }
    alcoholServer:allFile(filter: { name: { eq: "usx_al_ge_hero" } }) {
        nodes {
            name
            extension
            childImageSharp {
                gatsbyImageData
            }
            publicURL
        }
    }
    al_es:allFile(filter: { name: { eq: "usx_al_es_hero" } }) {
        nodes {
            name
            extension
            childImageSharp {
                gatsbyImageData
            }
            publicURL
        }
    }
    rbs:allFile(filter: { name: { eq: "usx_ca_hero" } }) {
        nodes {
            name
            extension
            childImageSharp {
                gatsbyImageData
            }
            publicURL
        }
    }
    rbses:allFile(filter: { name: { eq: "usx_al_ca_es_hero" } }) {
        nodes {
            name
            extension
            childImageSharp {
                gatsbyImageData
            }
            publicURL
        }
    }
    basset:allFile(filter: { name: { eq: "usx_al_il_hero" } }) {
        nodes {
            name
            extension
            childImageSharp {
                gatsbyImageData
            }
            publicURL
        }
    }
    medal:allFile(filter: { name: { eq: "usx_medal" }}) {
        nodes {
            name
            publicURL
            childImageSharp {
                gatsbyImageData
            }
        }
    }
    profile:allFile(filter: { name: { eq: "usx_profile" }}) {
        nodes {
            name
            publicURL
            childImageSharp {
                gatsbyImageData
            }
        }
    }
    dollar:allFile(filter: { name: { eq: "usx_dollar" }}) {
        nodes {
            name
            publicURL
            childImageSharp {
                gatsbyImageData
            }
        }
    }
    chevron:allFile(filter: { name: { eq: "usx_chev_down" }}) {
        nodes {
            name
            publicURL
            childImageSharp {
                gatsbyImageData
            }
        }
    }
    info:allFile(filter: { name: { eq: "usx_info" }}) {
        nodes {
            name
            publicURL
            childImageSharp {
                gatsbyImageData
            }
        }
    }
    alert:allFile(filter: { name: { eq: "usx_alert" }}) {
        nodes {
            name
            publicURL
            childImageSharp {
                gatsbyImageData
            }
        }
    }
}
`;
