import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Header2 } from 'src/components/core/typography';
import { Section, SectionFullWidth } from 'src/components/core/Section';
import { CoursePageInfoSectionStoryblok } from 'src/storyblok-component-types';
import { Slug } from 'src/lib';
import { TabNavItem, TabContent } from 'src/components/shared/Tabs';
import { AccordionItem } from 'src/components/shared/Accordion';

import RichText from '../core/RichText';

interface StoryProps {
    story: Slug;
}

export default (props: CoursePageInfoSectionStoryblok & StoryProps) => {
    const [activeTab, setActiveTab] = useState("tab0"); 
    const faqCards = props.faq || [];

    interface TextItem {
        text: string;
    }
    
    interface ContentItem {
        type: string;
        content?: TextItem[];
    }

    // Helper function to extract text from rich text JSON
    const extractText = (content: { content?: ContentItem[] } | undefined): string => {
        if (!content || !Array.isArray(content.content)) return '';
        return content.content
            .map((item) => {
                if (item.type === 'paragraph' && item.content) {
                    return item.content.map((textItem) => textItem.text).join(' ');
                }
                return '';
            })
            .join('\n');
    };

    // Generate FAQ Schema
    const faqSchema = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": faqCards.map((faqGroup) => 
            faqGroup.faq?.map((faq) => {
                const question = faq.title;
                const answerText = extractText(faq.intro) || "No answer provided";
                
                return {
                    "@type": "Question",
                    "name": question,
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": answerText
                    }
                };
            })
        ).flat()
    };

    return <SectionFullWidth className={props.className || ""}>
        <Helmet>
            {faqCards.length > 0 && (
                <script type="application/ld+json">
                    {JSON.stringify(faqSchema)}
                </script>
            )}
        </Helmet>
        <Section>
            <div className="col-span-12"> 
                <Header2 className="text-center">{props.title}</Header2>
                <RichText className="text-center w-5/6 m-auto text-lg mb-10 hidden md:block" document={props.desc || ""}/>
            </div>

            {/* New Tabs */}
            <div className="grid grid-cols-12">
                {/* Desktop */}
                <ul className="tab-nav hidden md:flex col-span-4 flex-col flex-wrap list-none border-b-0 pl-0 mr-20">
                    {faqCards.map((faq, i) => {
                        return <TabNavItem key={i} title={faq.title} id={`tab${i}`} activeTab={activeTab} setActiveTab={setActiveTab} className={`menu text-lg text-navy font-semibold hover:no-underline border-b-1 border-transparent hover:border-navy pb-2 !mb-8 w-full block cursor-pointer leading-6 ${i == 0 ? "first" : ""}`}/>
                    })}   
                </ul>
                <div className="tab-content hidden md:block col-span-12 md:col-span-8 relative">
                    {faqCards.map((faqGroup, i) => {
                        const random = Math.floor(Math.random() * 100000) + 1;
                        const [clicked, setClicked] = useState(0); // Set initial state to 0 for first item open

                        const handleToggle = (index:number) => {
                            setClicked(clicked === index ? -1 : index); // Toggle the clicked item
                        };

                        return <div key={i}><h2 className="md:hidden text-lg text-navy font-semibold mb-4">{faqGroup.title}</h2>
                        <TabContent id={`tab${i}`} activeTab={activeTab} className={`tab-body ${i == 0 ? "first" : ""}`}>
                            {/* New Accordion */}
                            <div className="accordion w-full" id={`accordion_${random}`}>
                                {faqGroup.faq?.map((y, index) => {
                                    return <AccordionItem key={index} i={index} title={y.title} description={y.intro} extended={y.desc} onToggle={() => handleToggle(index)} active={clicked === index} faq={true}
                                        className={`faq border-solid border-1 border-melrose md:border-transparent p-6 pl-14 rounded-xl mb-6 overflow-hidden last:mb-0 w-full ${props.layout === "title-right" || props.layout === "title-right-img" ? "bg-white" : "bg-white md:bg-lilac"} `} />
                                })}
                            </div>
                        </TabContent>       
                        </div>
                    })}  
                </div>
                {/* Mobile */}
                <div className="md:hidden col-span-12 relative">
                    {faqCards.map((faqGroup, index) => {
                        const [clicked, setClicked] = useState(0); // Set initial state to 0 for first item open

                        const handleToggle = (index:number) => {
                            setClicked(clicked === index ? -1 : index); // Toggle the clicked item
                        };

                        return <div className="col-span-12" key={index}>
                            <h2 className="md:hidden text-lg text-navy font-semibold mb-4">{faqGroup.title}</h2>
                            {/* New Accordion */}
                            <div className="accordion w-full mb-6" id={`accordion`}>                            
                                {faqGroup.faq?.map((y, index) => {                  
                                    return <AccordionItem key={index} i={index} title={y.title} description={y.intro} extended={y.desc} onToggle={() => handleToggle(index)} active={clicked === index} faq={true}
                                        className={`faq border-solid border-1 border-melrose md:border-transparent p-6 pl-14 rounded-xl mb-6 overflow-hidden last:mb-0 w-full ${props.layout === "title-right" || props.layout === "title-right-img" ? "bg-white" : "bg-white md:bg-lilac"} `} />
                                })}
                            </div>
                        </div>
                    })}   
                </div>
                
            </div>

            <RichText className="md:hidden text-center" document={props.desc || ""}/>
        </Section>
    </SectionFullWidth>;
}
