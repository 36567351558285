import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Helmet } from 'react-helmet';

import { Header2 } from 'src/components/core/typography';
import { Section, SectionFullWidth } from 'src/components/core/Section';
import { CoursePageInfoSectionStoryblok } from 'src/storyblok-component-types';
import { getFilename, findMatchingLocalFileNode, DynamicImage, Slug } from 'src/lib';
import { AccordionItem } from 'src/components/shared/Accordion';

import RichText from '../core/RichText';

interface StoryProps {
    story: Slug;
}

export default (props: CoursePageInfoSectionStoryblok & StoryProps) => {
    const infoCards = props.info || [];
    const random = Math.floor(Math.random() * 100000) + 1;
    const heroImageLocalFileNode = findMatchingLocalFileNode(getFilename(props.image?.filename || ""), props.story);
    const [clicked, setClicked] = useState(0);

    const handleToggle = (index:number) => {
        if (clicked === index) {
            return setClicked(0);
        }
        setClicked(index);
    };

    interface TextItem {
        text: string;
    }
    
    interface ContentItem {
        type: string;
        content?: TextItem[];
    }

    // Helper function to extract text from rich text JSON
    const extractText = (content: { content?: ContentItem[] } | undefined): string => {
        if (!content || !Array.isArray(content.content)) return '';
        return content.content
            .map((item) => {
                if (item.type === 'paragraph' && item.content) {
                    return item.content.map((textItem) => textItem.text).join(' ');
                }
                return '';
            })
            .join('\n');
    };

    // Generate filtered FAQ Schema
    const faqSchema = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": infoCards
            .map((card) => {
                const question = card.Title;
                const answerText = extractText(card.Description);
                
                // Only include questions with non-empty answers
                if (answerText) {
                    return {
                        "@type": "Question",
                        "name": question,
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": answerText
                        }
                    };
                }
                return null;
            })
            .filter((faq) => faq !== null) // Filter out null values (empty answers)
    };

    return <SectionFullWidth className={props.className || ""}>
         <Helmet>
            {faqSchema.mainEntity.length > 0 && (
                <script type="application/ld+json">
                    {JSON.stringify(faqSchema)}
                </script>
            )}
        </Helmet>
        <Section className="grid grid-cols-12 md:gap-10"> 
            <div className={props.layout == "title-left" || heroImageLocalFileNode ? "col-span-12 md:col-span-4 lg:mr-16 flex md:block justify-center" : "hidden"}>
                {props.layout == "title-left" ? <><Header2>{props.title}</Header2><ReactMarkdown>{props.desc}</ReactMarkdown></> : null}
                {!heroImageLocalFileNode ? null : <DynamicImage fileNode={heroImageLocalFileNode} alt={props.title} className="mb-6 md:mb-0 max-w-[300px] md:max-w-full"/> }
            </div>
            <div className={props.layout == "title-left" || heroImageLocalFileNode ? "col-span-12 md:col-span-8 col-end-13" : "col-span-12 col-end-13"}>
                <div className="p">
                    {props.layout === "title-right" && (<div className="mb-6 text-center"><Header2>{props.title}</Header2><RichText document={props.desc} className="text-lynch text-center m-auto w-5/6 text-md lg:text-lg mb-8"/></div>) }
                    {props.layout === "title-right-img" && (<div className="mb-6 text-center md:text-left"><Header2>{props.title}</Header2><RichText document={props.desc} className="text-lynch text-center md:text-left m-auto text-md lg:text-lg mb-8"/></div>)}
                </div>

                {/* New Accordion */}
                <div className="accordion w-full" id={`accordion_${random}`}>
                    {infoCards.map((card, index) => {
                        return <AccordionItem key={index} i={index} title={card.Title} description={card.Description} onToggle={() => handleToggle(index)} active={clicked === index} faq={false}
                        className={`border-solid border-1 border-melrose md:border-transparent p-6 rounded-xl mb-6 overflow-hidden last:mb-0 w-full ${props.layout === "title-right" || props.layout === "title-right-img" ? "bg-white md:border-dodger" : "bg-white md:bg-lilac"} `}/>
                    })}
                </div>

                <div className="mt-6">
                    {props.disclaimer == '' ? '' : <RichText className="text-center text-lynch" document={props.disclaimer}/>}
                </div>
            </div>
        </Section>
    </SectionFullWidth>;
}
