import React from "react";
import { ReactNode } from 'react';

interface Props {
    id: number | string;
    activeTab: number | string;
    children: ReactNode;
    className: string;
}

const TabContent = ({id, activeTab, children, className}:Props) => {
 return (
   activeTab === id ? <div className={className}>
     { children }
   </div>
   : null
 );
};
 
export default TabContent;