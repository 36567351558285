import React from "react";
import RichText from '../../core/RichText';
import ReactMarkdown from 'react-markdown';
import { graphql, useStaticQuery } from 'gatsby';
import { DynamicImage } from 'src/lib';
import { Header5 } from 'src/components/core/typography';
import "./AccordionItem.scss";
import { StoryblokRichtext } from "storyblok-rich-text-react-renderer-ts";

interface Props {
  title?: string;
  description?: string;
  percentage?: string;
  extended?: StoryblokRichtext;
  onToggle: (index: number) => void;
  active: Boolean;
  className: string;
  i: number;
  faq: Boolean;
  markdown?: Boolean;
}

const AccordionItem = ({ title, description, percentage, extended, onToggle, active, className, i, faq, markdown }: Props) => {
  const imageData = useStaticQuery(imageQuery);
  return (
    <div className={`accordion_item ${active ? "active" : ""} ${className}`} key={i}>
      <div className="button cursor-pointer" onClick={() => onToggle(i)}>
        <div className={`flex justify-between lg:justify-evenly ${active ? "mb-4" : ""}`}>
          <Header5 className={`mb-0 !text-navy ${percentage ? "md:w-11/12 lg:w-6/12" : "w-full"}`}>{title || ""}</Header5>
          {percentage ? (
            <>
              <Header5 className="mb-0 !text-navy lg:w-5/12 hidden lg:block">{percentage || ""}</Header5>
              <Header5 className="mb-0 !text-navy w-1/12 flex justify-end">
                {active ? (
                  <DynamicImage fileNode={imageData.close.nodes[0]} alt="Close Icon" width={20} height={20} loading="lazy" />
                ) : (
                  <DynamicImage fileNode={imageData.open.nodes[0]} alt="Open Icon" width={20} height={20} loading="lazy" />
                )}
              </Header5>
            </>
          ) : null}
        </div>
        <div className="preview">
          {faq && <RichText className={`hidden md:block text-lynch pt-4 h-[2.5rem] overflow-hidden ${active ? "md:hidden" : ""}`} document={description || ""} />}
          {faq && <p className={`hidden md:block text-navy mt-3 cursor-pointer !bg-transparent ${active ? "md:hidden" : ""}`}>Read More</p>}
        </div>
      </div>
      <div className={`answer_wrapper ${active ? "open" : ""}`}>
        {percentage ? <p className="font-semibold lg:hidden">Percentage of Exam: {percentage}</p> : null}
        {!markdown && <RichText className="answer text-lynch w-full" document={description || ""} />}
        {markdown && <ReactMarkdown>{description || ""}</ReactMarkdown>}
        {!markdown && extended && <RichText className="extended text-lynch pt-2" document={extended || ""} />}
      </div>
    </div>
  );
};

export default AccordionItem;


const imageQuery = graphql`
query {
    open:allFile(filter: { name: { eq: "usx_accordion_open" }}) {
        nodes {
            name
            publicURL
            childImageSharp {
                gatsbyImageData
            }
        }
    }
    close:allFile(filter: { name: { eq: "usx_accordion_close" }}) {
        nodes {
            name
            publicURL
            childImageSharp {
                gatsbyImageData
            }
        }
    }
}
`;
