import React from "react";

export const Tooltip = ({ message, children }: { message: string, children: string | JSX.Element | JSX.Element[] }) => {
    return (
        <div className="relative flex flex-col items-center group cursor-pointer">
        {children}
        <div className="absolute top-0 -right-10 md:right-auto w-52 flex-col items-center text-left hidden mt-6 group-hover:flex">
          <span className="relative z-10 p-4 text-xs text-black whitespace-no-wrap bg-white shadow-lg rounded-md">{message}</span>
        </div>
      </div>
    );
};