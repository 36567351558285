import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { graphql, useStaticQuery } from 'gatsby';
import { DynamicImage } from 'src/lib';
import { Section, SectionFullWidth } from 'src/components/core/Section';
import { PricingStoryblok } from 'src/storyblok-component-types';
import { Header2 } from 'src/components/core/typography';
import { Tooltip } from './tooltip';
import { CourseData, CourseTypeData } from 'src/components/coursePages/types';
import { BuyNowButton } from './buttonV2'; 

interface TableHeader {
    _uid: string;
    value: string;
}

interface TableCell {
    _uid: string;
    value: string;
}

interface TableRow {
    _uid: string;
    body: TableCell[];
}

const Proctoring = () => {
    const imageData = useStaticQuery(imageQuery);
    return <span className="font-light">
        <Tooltip message="An authorized proctor will observe you via webcam to confirm your identity and oversee exam participation. Proctoring services are available 24/7.">
        <DynamicImage fileNode={imageData.info.nodes[0]} alt="Info Icon" width={20} height={20} className="" loading="lazy"/>
        </Tooltip>
    </span>;
};

const PricingSection = ({ context, ...props }: { context: CourseData, [key: string]: any }) => {
    const { title = '', desc = '', table_desktop } = props;
    const imageData = useStaticQuery(imageQuery);
    const productNumbers = context.type === "fpm" ? [25, 24, 26, 7, 27] : [26, 25];
    
    return (
        <SectionFullWidth className={`bg-white ${props.className}`} id="programOptions">
            <Section>
                <Header2 className="max-w-full text-center">{title}</Header2>
                <ReactMarkdown className="text-center w-5/6 m-auto text-md lg:text-lg mb-14">{desc || ""}</ReactMarkdown>

                {/* Desktop Version for FPM */}
                { context.type === "fpm" ? (
                    <div className="hidden lg:block">
                    <div className="border border-mischka rounded-md">
                    <table className="w-full text-left table-fixed text-bluewood font-semibold">
                    <thead>
                        <tr className="bg-lilac">
                            {table_desktop?.thead.map((header: TableHeader, index: number) => {
                                const parts = header.value.split('|');
                                return (
                                    <th key={header._uid} className={`px-4 pt-9 pb-7 border-r last:border-r-0 ${
                                        index === 0 ? 'w-1/4 text-lg' : 
                                        index === 4 ? 'w-1/6 text-md text-center bg-cornflower pricing-best text-white' : 
                                        'w-1/6 text-center'
                                        }`} scope="col" >
                                            {index === 4 && (
                                                <div className="pricing-value" />
                                            )}
                                        <div className="">
                                            {parts.map((part, idx) => (
                                                <div key={idx} className={
                                                    idx === 0 ? 'font-semibold' :
                                                    idx === 1 && index === 4 ? 'text-lg text-white inline' : 
                                                    idx === 1 ? 'text-lg text-dodger inline' : 
                                                    idx === 2 ? 'ml-1 text-sm line-through font-medium inline' : ''}>{part}</div>
                                            ))}
                                        </div>
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {table_desktop?.tbody.map((row: TableRow, rowIndex: number) => (
                            <tr key={row._uid} className={`${rowIndex === 1 || rowIndex === 3 ? 'bg-lilac' : ''}`}>
                                {row.body.map((cell, index) => (
                                    <td
                                        key={cell._uid}
                                        className={`p-4 border-t border-r last:border-r-0 ${
                                            index === 0 ? 'w-1/4' : 
                                            index === 4 ? 'w-1/6 text-center bg-melrose20' : 
                                            'w-1/6 text-center'}`}
                                    >
                                        {cell.value === 'True' ? (
                                            <DynamicImage fileNode={imageData.checkmark.nodes[0]} alt="Checkmark Icon" width={17} height={15} className="m-auto" loading="lazy" />
                                        ) : (
                                            <>
                                                {rowIndex === 2 && index === 0 ? (
                                                    <span className="flex justify-between">
                                                        {cell.value}
                                                        <Proctoring />
                                                    </span>
                                                ) : (
                                                    cell.value
                                                )}
                                            </>
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))}
                        <tr>
                            <td className="p-2 border-t border-r w-1/4"></td>
                            {[...Array(5)].map((_, index: number) => {
                                const productNumber = productNumbers[index];
                                return (
                                    <td key={index} className={`p-4 border-t border-r last:border-r-0 w-1/6 text-center ${
                                        index === 3 ? 'bg-melrose20' : ''}`}>
                                        <BuyNowButton id="course-page-enroll-button" productNumber={productNumber} customClass="px-2">
                                            <p className="text-inherit !mb-0" data-test="enroll-button">Enroll Now</p>
                                        </BuyNowButton>
                                    </td>
                                );
                            })}
                        </tr>
                    </tbody>
                    </table>
                    </div>
                    </div>
                ) : <></> }

                {/* Desktop and Mobile Version for FPM_ALT */}
                { context.type === "fpm_alt" ? (
                    <div className="flex flex-col md:flex-row w-100 gap-8 xl:gap-12">
                    {table_desktop?.thead.slice(1).map((header: TableHeader, index: number) => (
                        <div key={header._uid} className="w-full">
                            <div className={`rounded-xl w-full shadow-xl text-bluewood font-semibold ${index === 0 ? 'bg-hawkes-blue' : 'bg-lilac'}`}>
                                <div key={header._uid} className="px-8 pt-8 text-center">               
                                    <div className="font-semibold text-lg md:text-2xl mb-4">
                                        {header.value.split('|')[0]}
                                    </div>
                                    {header.value.split('|')[1] && (
                                        <div className="text-2xl md:text-4xl inline">
                                            {header.value.split('|')[1]}
                                        </div>
                                    )}
                                    {header.value.split('|')[4] && (
                                        <div className="text-sm line-through font-medium inline ml-1">
                                            {header.value.split('|')[4]}
                                        </div>
                                    )}
                                </div>
                                <div className="my-6 w-[250px] block m-auto">
                                    {table_desktop?.tbody.map((row: TableRow, rowIndex: number) => (
                                        <div key={row._uid}>
                                            <div className="grid text-left text-md font-normal gap-3 grid-flow-col auto-cols-max items-center leading-relaxed">
                                                {row.body[index + 1]?.value.includes('True') ? (
                                                    <>
                                                    <DynamicImage fileNode={imageData.checkmark.nodes[0]} alt="Checkmark Icon" width={15} height={15} loading="lazy" />{row.body[index + 1].value.replace("True", "")} { row.body[0].value }
                                                    </>
                                                ) : (
                                                    <>
                                                    <DynamicImage fileNode={imageData.cross.nodes[0]} alt="Cross Icon" width={15} height={15} loading="lazy" />{row.body[index + 1].value} { row.body[0].value }
                                                    </>                                      
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className="pb-8 px-8 w-full lg:w-[300px] m-auto text-center">
                                    <BuyNowButton id="course-page-enroll-button" productNumber={productNumbers[index]} >
                                        <p className="text-inherit !mb-0" data-test="enroll-button">Enroll Now</p>
                                    </BuyNowButton>
                                </div>
                            </div>
                        </div>
                    ))}
                    </div>
                ) : <></> }

                {/* Mobile Version for FPM */}
                { context.type === "fpm" ? (
                <div className="block lg:hidden">
                    {table_desktop?.thead.slice(1).map((header: TableHeader, index: number) => (
                        <div key={header._uid} className="rounded-md border border-mischka mb-12 shadow-xl">
                        <table className="w-full table-fixed text-bluewood font-semibold ">
                            <thead>
                                <tr className="bg-lilac">
                                <th key={header._uid} colSpan={2} className={`px-4 py-6 w-1/2 text-lg text-center ${
                                        index === 3 ? ' bg-cornflower pricing-best !text-white rounded-t-md' : 
                                        ''
                                        }`} scope="col" >
                                            {index === 3 && (
                                                <div className="pricing-value" />
                                            )}
                                    
                                        <div className="font-semibold">
                                            {header.value.split('|')[0]}
                                        </div>
                                        {header.value.split('|')[1] && (
                                            <div className={`text-lg ${index === 3 ? 'text-white' : 'text-dodger'} inline`}>
                                                {header.value.split('|')[1]}
                                            </div>
                                        )}
                                        {header.value.split('|')[2] && (
                                            <div className="text-sm line-through font-medium inline ml-1">
                                                {header.value.split('|')[2]}
                                            </div>
                                        )}
                                    </th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {table_desktop?.tbody.map((row: TableRow, rowIndex: number) => (
                                    <tr key={row._uid} className={`${(rowIndex === 1 || rowIndex === 3) ? 'bg-lilac' : ''}`}>
                                        <td className="p-4 border-t border-r w-1/2 font-semibold text-sm">               
                                        {rowIndex === 2 ? (
                                            <span className="flex justify-between gap-1">
                                                {row.body[0].value}
                                                <Proctoring/>
                                            </span>
                                        ) : (
                                            row.body[0].value
                                        )}
                                        </td>
                                        <td className="p-4 border-t w-1/2 text-center text-sm">
                                            {row.body[index + 1]?.value === 'True' ? (
                                                <DynamicImage fileNode={imageData.checkmark.nodes[0]} alt="Checkmark Icon" width={17} height={15} className="m-auto" loading="lazy" />
                                            ) : (
                                                <>
                                                    {row.body[index + 1]?.value}
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td className="p-6 w-1/2 text-center border-t" colSpan={2}>
                                        <BuyNowButton id="course-page-enroll-button" productNumber={productNumbers[index]}>
                                            <p className="text-inherit !mb-0" data-test="enroll-button">Enroll Now</p>
                                        </BuyNowButton>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    ))}
                </div>
                ) : <></> }
                
            </Section>
        </SectionFullWidth>
    );
};

export default PricingSection;

const imageQuery = graphql`
query {
    checkmark:allFile(filter: { name: { eq: "usx_pricing_check" }}) {
        nodes {
            name
            publicURL
            childImageSharp {
                gatsbyImageData
            }
        }
    }
    cross:allFile(filter: { name: { eq: "usx_pricing_cross" }}) {
        nodes {
            name
            publicURL
            childImageSharp {
                gatsbyImageData
            }
        }
    }    
    info:allFile(filter: { name: { eq: "usx_pricing_info" }}) {
        nodes {
            name
            publicURL
            childImageSharp {
                gatsbyImageData
            }
        }
    }
}
`;
