import React from 'react';
import { graphql, PageProps } from "gatsby";
import Head from 'src/components/head';
import Layout from 'src/components/layout';
import { CourseTemplateProps } from 'src/lib/storyblokSourceTypes';
import { CourseData } from 'src/components/coursePages/types';
import { CoursePageStoryblok, CoursePageInfoSectionStoryblok, BenefitsStoryblok, AboutUsStoryblok, TestimonialsStoryblok, SeoStoryblok, PricingStoryblok } from "src/storyblok-component-types";
import HeroSection from './hero';
import HeroV2Section from './heroV2';
import CourseInfoSection from './courseInfo';
import CourseInfoSectionV2 from './courseInfoV2';
import FaqsSection from './faqs';
import TabsSection from './tabs';
import BenefitsSection from './benefits';
import PricingSection from './pricing';
import { AboutUsSection, TestimonialsSection } from 'src/components/sections/landing';

const pageStyles = {
  fontFamily: "Inter,sans-serif",
}

// Helper function to parse content strings to JSON
const parseContent = <T extends {}>(story?: { content?: string }): T | null => {
  if (!story?.content) return null;
  try {
    return JSON.parse(story.content) as T;
  } catch (e) {
    console.error(`Error parsing content: ${e}`);
    return null;
  }
};

export default ({ data, pageContext }: PageProps<CourseTemplateProps, CourseData>) => {
  // Helper function to filter stories by slug or match pattern
  const filterStories = (stories: any[], pattern: string, state?: string) => {
    return stories.filter(story => story.full_slug.match(pattern))
      .filter(story => state ? story.slug.toLowerCase().includes(state.replace(/\s+/g, '').toLowerCase()) : true)[0];
  };

  // Retrieve storyblok data by section
  const heroStories = data.hero?.nodes || [];
  const courseInfoStories = data.courseInfo?.nodes || [];
  const benefitsStories = data.benefits?.nodes || [];
  const accountsStories = data.accounts?.nodes || [];
  const featuresStories = data.features?.nodes || [];
  const testimonialsStories = data.testimonials?.nodes || [];
  const faqsStories = data.faqs?.nodes || [];
  const seoStories = data.seo?.nodes || [];
  const pricingStories = data.pricing?.nodes || [];

  let heroStory, courseInfoStory, benefitsStory, accountsStory, featuresStory, testimonialsStory, faqsStory, seoStory, pricingStory;

  if (pageContext.type === "fh_ansi") {
    // Filter storyblok data by the fh_ansi course type and state
    const state = pageContext.state.replace(/\s+/g, '').toLowerCase();
    heroStory = filterStories(heroStories, "courses/course-pages/hero/fh_ansi/", state);
    courseInfoStory = filterStories(courseInfoStories, "courses/course-pages/course-info/fh_ansi/", state);
    benefitsStory = filterStories(benefitsStories, "courses/course-pages/benefits/fh_ansi/", state);
    accountsStory = filterStories(accountsStories, "courses/course-pages/accounts/fh_ansi/", state);
    featuresStory = filterStories(featuresStories, "courses/course-pages/features/fh_ansi/", state);
    testimonialsStory = filterStories(testimonialsStories, "courses/course-pages/testimonials/fh_ansi/", state);
    faqsStory = filterStories(faqsStories, "courses/course-pages/faqs/fh_ansi/", state);
    seoStory = filterStories(seoStories, "courses/course-pages/seo/fh_ansi/", state);
  } else if (pageContext.type === "fpm_alt") {
    // Filter storyblok data by the fh_ansi course type and state
    const state = pageContext.state.replace(/\s+/g, '').toLowerCase();
    heroStory = filterStories(heroStories, "courses/course-pages/hero/fpm_alt/", state);
    courseInfoStory = filterStories(courseInfoStories, "courses/course-pages/course-info/fpm_alt/", state);
    benefitsStory = filterStories(benefitsStories, "courses/course-pages/benefits/fpm_alt/", state);
    accountsStory = filterStories(accountsStories, "courses/course-pages/accounts/fpm_alt/", state);
    featuresStory = filterStories(featuresStories, "courses/course-pages/features/fpm_alt/", state);
    testimonialsStory = filterStories(testimonialsStories, "courses/course-pages/testimonials/fpm_alt/", state);
    faqsStory = filterStories(faqsStories, "courses/course-pages/faqs/fpm_alt/", state);
    seoStory = filterStories(seoStories, "courses/course-pages/seo/fpm_alt/", state);
    pricingStory = filterStories(pricingStories, "courses/course-pages/pricing/fpm_alt/", state);
  } else {
    // Filter storyblok data by the course type for this page
    heroStory = heroStories.find(story => story.slug === pageContext.type);
    courseInfoStory = courseInfoStories.find(story => story.slug === pageContext.type);
    benefitsStory = benefitsStories.find(story => story.slug === pageContext.type);
    accountsStory = accountsStories.find(story => story.slug === pageContext.type);
    featuresStory = featuresStories.find(story => story.slug === pageContext.type);
    testimonialsStory = testimonialsStories.find(story => story.slug === pageContext.type);
    faqsStory = faqsStories.find(story => story.slug === pageContext.type);
    seoStory = seoStories.find(story => story.slug === pageContext.type);
    pricingStory = pricingStories.find(story => story.slug === pageContext.type);
  }

  // Parse content strings to json
  const heroContent = parseContent<CoursePageStoryblok>(heroStory);
  const courseInfoContent = parseContent<CoursePageInfoSectionStoryblok>(courseInfoStory);
  const benefitsContent = parseContent<BenefitsStoryblok>(benefitsStory);
  const accountsContent = parseContent<AboutUsStoryblok>(accountsStory);
  const featuresContent = parseContent<BenefitsStoryblok>(featuresStory);
  const testimonialsContent = parseContent<TestimonialsStoryblok>(testimonialsStory);
  const faqsContent = parseContent<CoursePageInfoSectionStoryblok>(faqsStory);
  const seoContent = parseContent<SeoStoryblok>(seoStory);
  const pricingContent = pricingStory ? parseContent<PricingStoryblok>(pricingStory) : null; 

  if (!heroContent || !courseInfoContent || !benefitsContent || !accountsContent ||
    !featuresContent || !testimonialsContent || !faqsContent || !seoContent) {
    console.error(`Error fetching data for page ${pageContext.url}`)
    return null; // error or 404
  }

  let pageLayout: string;

  // Determine page layout
  const legacyCourses = ["rbses", "basset", "al", "al_es"];
  const fpmCourses = ["fpm", "fpm_alt"];
  if (legacyCourses.includes(pageContext.type)) {
    pageLayout = "legacy";
  } else if (fpmCourses.includes(pageContext.type)) {
    pageLayout = "fpm";
  } else {
    pageLayout = "standard";
  }

  // Replace $STATE with a defined state
  const replaceStatePlaceholder = <T extends {}>(content: T): T => {
    return JSON.parse(JSON.stringify(content).replace(/\$STATE/g, pageContext.state));
  };

  const heroContentWithState = replaceStatePlaceholder(heroContent);
  const benefitsContentWithState = replaceStatePlaceholder(benefitsContent);
  const faqsContentWithState = replaceStatePlaceholder(faqsContent);
  const courseInfoContentWithState = replaceStatePlaceholder(courseInfoContent);

  return (
    <div>
      <Head seo={seoContent} coursePageContext={pageContext} storyblokData={seoContent} />
      <Layout headerProps={{ highlightPathname: "/us/programs" }}>
        <main style={pageStyles}>
          {pageLayout === "standard" && (
            <span>
              <HeroSection content={heroContentWithState} context={pageContext} heroStory={heroStory} />
              <CourseInfoSection {...courseInfoContentWithState} className="bg-gradient-to-b from-titan to-white" story={courseInfoStory} />
              <BenefitsSection {...benefitsContentWithState} story={benefitsStory} />
              <TestimonialsSection {...testimonialsContent} />
              <TabsSection {...accountsContent} story={accountsStory} />
              <FaqsSection {...faqsContentWithState} story={faqsStory} />
            </span>
          )}

          {pageLayout === "fpm" && (
            <span>
              <HeroV2Section content={heroContentWithState} context={pageContext} heroStory={heroStory} />
              <BenefitsSection {...benefitsContentWithState} story={benefitsStory} className="bg-lilac"/>
              {pricingContent && ( 
                <PricingSection {...pricingContent} context={pageContext} />
              )}
              <CourseInfoSectionV2 {...courseInfoContentWithState} className="bg-gradient-to-b from-titan to-white" story={courseInfoStory} />
              <TestimonialsSection {...testimonialsContent} />
              <TabsSection {...accountsContent} story={accountsStory} />
              <FaqsSection {...faqsContentWithState} story={faqsStory} />
            </span>
          )}

          {pageLayout === "legacy" && (
            <span>
              <HeroSection content={heroContentWithState} context={pageContext} heroStory={heroStory} />
              <CourseInfoSection {...courseInfoContentWithState} className="bg-gradient-to-b from-titan to-white" story={courseInfoStory} />
              <BenefitsSection {...benefitsContentWithState} story={benefitsStory} />
              <TestimonialsSection {...testimonialsContent} />
              <CourseInfoSection {...faqsContentWithState} story={faqsStory} />
              <AboutUsSection {...accountsContent} story={accountsStory} />
              <BenefitsSection {...featuresContent} story={featuresStory} />
            </span>
          )}
        </main>
      </Layout>
    </div>
  );
};

export const pageQuery = graphql`
    query {
        hero: allStoryblokEntry(filter: { full_slug: { regex: "/^courses/course-pages/hero/[^/]*/" } }) {
            nodes {
                content
                slug
                full_slug
                imageFileSrc {
                    publicURL
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
        }
        courseInfo: allStoryblokEntry(filter: { full_slug: { regex: "/^courses/course-pages/course-info.*/" } }) {
            nodes {
                content
                slug
                full_slug
                imageFileSrc {
                    publicURL
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
        }
        benefits: allStoryblokEntry(filter: { full_slug: { regex: "/^courses/course-pages/benefits.*/" } }) {
            nodes {
                content
                slug
                full_slug
                imageFileSrc {
                    publicURL
                    childImageSharp {
                        fluid(toFormat: WEBP) {
                            base64
                            srcWebp
                            srcSetWebp
                            originalImg
                            originalName
                        }
                    }
                }
            }
        }
        accounts: allStoryblokEntry(filter: { full_slug: { regex: "/^courses/course-pages/accounts.*/" } }) {
            nodes {
                content
                slug
                full_slug
                imageFileSrc {
                    publicURL
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
        }
        features: allStoryblokEntry(filter: { full_slug: { regex: "/^courses/course-pages/features.*/" } }) {
            nodes {
                content
                slug
                full_slug
                imageFileSrc {
                    publicURL
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
        }
        testimonials: allStoryblokEntry(filter: { full_slug: { regex: "/^courses/course-pages/testimonials.*/" } }) {
            nodes {
                content
                slug
                full_slug
            }
        }
        pricing: allStoryblokEntry(filter: { full_slug: { regex: "/^courses/course-pages/pricing.*/" } }) {
          nodes {
              content
              slug
              full_slug
              imageFileSrc {
                  publicURL
                  childImageSharp {
                      gatsbyImageData
                  }
              }
          }
      }
        faqs: allStoryblokEntry(filter: { full_slug: { regex: "/^courses/course-pages/faqs.*/" } }) {
            nodes {
                content
                slug
                full_slug
            }
        }
        seo: allStoryblokEntry(filter: { full_slug: { regex: "/^courses/course-pages/seo.*/" } }) {
            nodes {
                content
                slug
                full_slug
                imageFileSrc {
                    publicURL
                }
            }
        }
    }
`;
