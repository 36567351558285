import React from 'react';
import { navigate } from 'gatsby';

interface BuyNowButtonProps {
    children: React.ReactNode;
    id: string;
    productNumber: number | undefined;
    customClass?: string;
}

export const BuyNowButton = ({ children, id, productNumber, customClass }: BuyNowButtonProps) => {
    const handleButtonClick = () => {
        if (productNumber !== undefined) {
            const PURCHASE_REDIRECT = `https://my.userve.com/urlcheckout/add?product=${productNumber}&qty=1`;
            navigate(PURCHASE_REDIRECT);
        } else {
            console.error('Product number is undefined');
        }
    };

    return (
        <button 
            className={`btn btn-primary hover:bg-dodger hover:border-dodger w-full ${customClass}`}  
            id={id} 
            onClick={handleButtonClick}
        >
            {children}
        </button>
    );
};